.recommended-request-wrapper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    padding: 0 12px;
}

.recommended-request-wrapper > span{
   text-align: left;
   width: 150px;
   overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.recommended-request-wrapper > *{
    margin: 0 16px !important;
}


span.recommended-request-reason{
    width: 180px;
}