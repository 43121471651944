body {
    margin: 0;
    font-family: 'Poppins', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MuiBackdrop-invisible {

}

.MuiBackdrop-root-MuiDialog-backdrop {
    background-color: rgba(217, 237, 255, 0.75);
}

.tooltip-tray {
    display: flex;
    margin-top: 11px;
    align-items: center;
}